<template>
  <div
    class="app-layout"
    :style="{
      '--layout-offset-top': hidden ? '0px' : undefined,
      '--launch-banner-height': !displayNewsBanner ? '0px' : undefined,
    }"
  >
    <div
      class="fixed z-50 w-full transition duration-navigation"
      :class="{
        '-translate-y-full': hidden,
      }"
    >
      <news-banner
        v-if="displayNewsBanner && currentNews"
        class="h-[--launch-banner-height] w-full"
        :news="currentNews"
        @close="acknowledgeNewsBanner"
      />

      <main-header
        class="h-[--top-nav-height]"
        :class="{ 'backdrop-blur': hasBlurredHeader || hasStartedScroll }"
        :no-navigation="noNavigation"
        :variant="
          hasTransparentHeader && !hasStartedScroll ? 'transparent' : undefined
        "
      />
    </div>

    <main
      class="min-h-screen"
      :class="{ 'pt-[var(--top-header-height)]': !hasTransparentHeader }"
      data-cy-main-content
    >
      <slot />
    </main>

    <app-page-wrapper v-if="!noFaq && faq?.length" class="py-32">
      <app-page-section title="Les questions les plus fréquentes">
        <customer-success-faq
          class="m-auto max-w-[900px]"
          :items="faq as FaqItem[]"
        />
      </app-page-section>
    </app-page-wrapper>

    <main-footer v-if="!noFooter" />
  </div>
</template>

<script setup lang="ts">
import type { FaqItem } from "~/business-areas/customer-success/customer-success.model";
import MainHeader from "~/layouts/_includes/components/main-header.vue";
import MainFooter from "~/layouts/_includes/main-footer.vue";
import NewsBanner from "~/layouts/_includes/news-banner.vue";

const properties = defineProps<{
  transparentHeader?: boolean;
}>();

const hidden = ref(false);
const hasStartedScroll = ref(false);

if (process.client) {
  const { directions, y } = useScroll(document);

  watchEffect(() => {
    hasStartedScroll.value = y.value >= 80;

    if (directions.bottom && hasStartedScroll.value) {
      hidden.value = true;
    }

    if (directions.top) {
      hidden.value = false;
    }
  });
}

const route = useRoute();
const hasTransparentHeader = computed(
  () =>
    Boolean(route.meta.transparentHeader) ||
    properties.transparentHeader ||
    (route.name === "enterprise" && !useFeatureFlag("enterprise_page_new")),
);
const hasBlurredHeader = computed(() =>
  Boolean(route.meta.blurredHeader ?? true),
);
const noFooter = computed(() => Boolean(route.meta.noFooter));
const noNavigation = computed(() => Boolean(route.meta.noNavigation));
const noFaq = computed(() => Boolean(route.meta.noFaq));

const { acknowledgeNewsBanner, displayNewsBanner, currentNews } =
  useNewsBanner();

const { data: faq, execute: refreshFaq } = useLazyFetch("/api/faq", {
  query: {
    page: computed(() => route.meta.faqPageFilter),
  },
  watch: false,
});

watch(
  () => route.meta.faqPageFilter,
  () => {
    refreshFaq();
  },
);
</script>

<style scoped>
.app-layout {
  --top-nav-height: 60px;
  --launch-banner-height: 100px;
  --top-header-height: calc(
    var(--top-nav-height) + var(--launch-banner-height)
  );
  --layout-offset-top: var(--top-header-height);
}

@media screen(md) {
  .app-layout {
    --top-nav-height: 80px;
    --launch-banner-height: 60px;
  }
}
</style>
